import React, { useEffect, useState } from 'react';
import Spinner from './Spinn';
import './HistoryModal.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

function HistoryModal({ isOpen, onClose, selectedFigure, onSummaryGenerated, onHistoryCleared }) {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchHistory();
    }
  }, [isOpen, selectedFigure]);

  const fetchHistory = () => {
    setIsLoading(true);
    try {
      const storedHistory = localStorage.getItem(`history_${selectedFigure.name}`);
      if (storedHistory) {
        setHistory(JSON.parse(storedHistory));
      } else {
        setHistory([]);
      }
    } catch (error) {
      console.error('Error loading history:', error);
      setHistory([{ role: 'error', content: 'Failed to load history' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSummary = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          history, 
          figureName: selectedFigure.name
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to generate summary');
      }
      const data = await response.json();
      
      // Update local storage with the new summary
      const updatedHistory = [{ role: 'assistant', content: data.summary }, ...history.slice(1)];
      localStorage.setItem(`history_${selectedFigure.name}`, JSON.stringify(updatedHistory));
      
      fetchHistory();
      onSummaryGenerated();
    } catch (error) {
      console.error('Error generating summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearFigureHistory = () => {
    if (window.confirm(`Are you sure you want to clear the history for ${selectedFigure.name}?`)) {
      try {
        localStorage.removeItem(`history_${selectedFigure.name}`);
        setHistory([]);
        alert('History cleared successfully');
        onHistoryCleared();
      } catch (error) {
        console.error('Error clearing figure history:', error);
        alert('Failed to clear history');
      }
    }
  };

  const handleClearAllHistory = () => {
    if (window.confirm('Are you sure you want to clear the history for all figures? This action cannot be undone.')) {
      try {
        Object.keys(localStorage).forEach(key => {
          if (key.startsWith('history_')) {
            localStorage.removeItem(key);
          }
        });
        setHistory([]);
        alert('All histories cleared successfully');
        onHistoryCleared();
      } catch (error) {
        console.error('Error clearing all history:', error);
        alert('Failed to clear all histories');
      }
    }
  };

  const formatSummary = (content) => {
    return content.split('\n').map((line, index) => {
      if (line.startsWith('🔍') || line.startsWith('🔑') || line.startsWith('💬') || line.startsWith('✨')) {
        return <h3 key={index} style={{ color: '#D4A539' }}>{line}</h3>;
      } else if (line.trim().startsWith('•')) {
        return <li key={index}>{line.trim().substring(1)}</li>;
      } else if (line.trim().match(/^\d+\./)) {
        return <li key={index}>{line.trim().substring(line.indexOf('.') + 1)}</li>;
      } else if (line.trim() !== '') {
        return <p key={index}>{line}</p>;
      }
      return null;
    });
  };

  if (!isOpen) {
    return null;
  }

  const summary = history.length > 0 && history[0].role === 'assistant' && history[0].content.includes('Conversation Overview') ? history[0] : null;
  const conversationHistory = summary ? history.slice(1) : history;

  return (
    <div className="history-modal-overlay">
      <div className="history-modal-content">
        <div className="modal-header">
          <button onClick={onClose} className="close-button">Close</button>
        </div>
        <h2 className="modal-title">{selectedFigure.name} Conversation History</h2>
        <div className="clear-buttons-container">
          <button onClick={handleClearFigureHistory} className="clear-button">Clear Figure History</button>
          <button onClick={handleClearAllHistory} className="clear-all-button">Clear All History</button>
        </div>
        <button onClick={handleSummary} className="summary-button">Generate Summary</button>
        {isLoading && <Spinner />}
        {summary && (
          <div className="summary-content">
            <div>{formatSummary(summary.content)}</div>
          </div>
        )}
        <div className="history-content">
          {conversationHistory.map((entry, index) => (
            <div key={index} className={`message ${entry.role}`}>
              <strong>{entry.role === 'user' ? 'User: ' : `${selectedFigure.name}: `}</strong>
              <span dangerouslySetInnerHTML={{ __html: entry.content }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HistoryModal;
export const historicalFigures = [
    {
        name: "Laozi",
        image: "assets/images/laozi.png",
        about: "Laozi, an ancient Chinese philosopher, is traditionally credited with founding Taoism and authoring the 'Tao Te Ching,' a seminal text that explores the concept of the Tao, an ineffable force that underlies the universe. He emphasized living in harmony with the Tao through simplicity, humility, and non-action (wu wei). Laozi's teachings contrast with Confucianism by advocating a natural and spontaneous way of living. His influence on Chinese culture, philosophy, and the development of Taoism is profound and enduring, impacting spiritual and philosophical thought worldwide."
    },
    {
        name: "Siddhartha Gautama",
        image: "assets/images/siddhartha.png",
        about: "Siddhartha Gautama, known as the Buddha, was a spiritual leader who founded Buddhism in the 5th century BCE. Born a prince in Nepal, he renounced his luxurious life in search of enlightenment. After years of meditation, he attained enlightenment under the Bodhi tree and taught the Four Noble Truths and the Noble Eightfold Path, emphasizing the impermanence of all things and the potential for all beings to achieve enlightenment. Buddhism, based on his insights, spread widely and remains a major world religion focused on ending suffering and achieving spiritual awakening."
    },
    {
        name: "Plato",
        image: "assets/images/plato.png",
        about: "Plato, an ancient Greek philosopher, was a student of Socrates and teacher of Aristotle. His works, primarily in the form of dialogues, cover a wide range of topics including philosophy, politics, and ethics. Plato's theory of Forms posits that the material world is a shadow of a higher, unchanging reality. His political philosophy, outlined in 'The Republic,' envisions an ideal state governed by philosopher-kings. Plato founded the Academy, one of the earliest institutions of higher learning, profoundly influencing Western thought, education, and the development of philosophical inquiry."
    },
    {
        name: "Arthur Schopenhauer",
        image: "assets/images/schopenhauer.png",
        about: "Arthur Schopenhauer, born in 1788, was a German philosopher known for his pessimistic philosophy. His seminal work, 'The World as Will and Representation,' posits that the will, an irrational force, drives all existence and is the source of suffering. Schopenhauer's ideas on the nature of reality, human behavior, and the inevitability of suffering influenced existentialism, psychology, and the arts. His emphasis on the irrational aspects of human nature and advocacy for asceticism and compassion continue to resonate, making him a significant and influential figure in Western philosophy."
    },
    {
        name: "Friedrich Nietzsche",
        image: "assets/images/nietzsche.png",
        about: "Friedrich Nietzsche, born in 1844, was a German philosopher known for his critiques of traditional morality and religion. His works, including 'Thus Spoke Zarathustra' and 'Beyond Good and Evil,' introduced concepts like the Übermensch and the proclamation 'God is dead.' Nietzsche's exploration of the will to power, the creation of individual values, and the critique of societal norms influenced existentialism, postmodernism, and cultural criticism. Despite his controversial ideas, Nietzsche's profound impact on philosophy, literature, and cultural thought endures, challenging and inspiring thinkers across disciplines."
    },
    {
        name: "Carl Gustav Jung",
        image: "assets/images/jung.png",
        about: "Carl Gustav Jung, born in 1875, was a Swiss psychiatrist and founder of analytical psychology. He introduced concepts such as the collective unconscious and archetypes, which are universal symbols shaping human experience. Jung's theories on individuation emphasize the integration of the conscious and unconscious for psychological wholeness. His work, including 'Psychological Types' and 'Man and His Symbols,' has profoundly influenced psychology, literature, and religious studies, offering deep insights into the human psyche and the symbolic nature of dreams, myths, and spiritual practices."
    },
    {
        name: "Simone de Beauvoir",
        image: "assets/images/beauvoir.png",
        about: "Simone de Beauvoir, born in 1908, was a French existentialist philosopher, writer, and feminist. Her seminal work, 'The Second Sex,' examines women's oppression and argues that gender is a social construct. Beauvoir's existentialist philosophy, developed alongside Jean-Paul Sartre, explores freedom, responsibility, and the human condition. Her novels, essays, and activism have had a lasting impact on feminist theory and existentialism, challenging societal norms and advocating for women's rights and intellectual freedom. Beauvoir's work continues to inspire and influence contemporary discussions on gender, identity, and liberation."
    },
    {
        name: "Marcus Aurelius",
        image: "assets/images/marcus.png",
        about: "Marcus Aurelius was a Roman emperor from 161 to 180 CE and a Stoic philosopher. Known for his work 'Meditations,' written during his military campaigns, he reflected on duty, resilience, and the transient nature of life. His reign, often seen as the last phase of the Pax Romana, was marked by efforts to rule justly and compassionately despite numerous challenges. Marcus Aurelius' commitment to Stoic principles, his emphasis on rationality, and his philosophical writings continue to inspire those seeking wisdom, virtue, and guidance in ethical living."
    },
    {
        name: "Rumi",
        image: "assets/images/rumi.png",
        about: "Rumi, born Jalāl ad-Dīn Muhammad Rūmī in 1207, was a Persian poet and Sufi mystic. His works, including the 'Masnavi,' explore themes of divine love, spirituality, and the human connection with the divine. Influenced by his spiritual mentor Shams of Tabriz, Rumi's poetry blends metaphor, imagery, and symbolic language to express the longing for union with God. His teachings emphasize love as the path to spiritual enlightenment. Rumi's poetry, with its universal themes and profound insights, continues to inspire readers worldwide, transcending cultural and religious boundaries and enriching spiritual literature."
    },
    {
        name: "Joseph Campbell",
        image: "assets/images/campbell.png",
        about: "Joseph Campbell, born in 1904, was an American mythologist known for his work in comparative mythology. His book 'The Hero with a Thousand Faces' introduces the concept of the hero's journey, a universal narrative pattern found in myths worldwide. Influenced by Carl Jung, Campbell explored archetypes and the collective unconscious. His work, including 'The Masks of God' series, has significantly impacted literature, film, and psychology, highlighting the enduring power of myth in human culture. Campbell's insights into the hero's journey continue to guide storytellers and resonate with audiences globally."
    },
    {
        name: "Dōgen Zenji",
        image: "assets/images/dogen.png",
        about: "Dōgen Zenji, a Japanese Buddhist priest, founded the Sōtō school of Zen Buddhism. Born in 1200, he traveled to China to deepen his understanding of Buddhism. Upon returning, he emphasized rigorous meditation practice (zazen) and direct experience of enlightenment. His key work, 'Shōbōgenzō,' explores themes like the nature of time and the unity of practice and enlightenment. Dōgen established the Eihei-ji monastery, advocating for a monastic life of mindfulness, simplicity, and adherence to the principles of Zen. His teachings remain influential in Zen Buddhism, emphasizing the importance of living in the present moment."
    },
    {
        name: "Jesus of Nazareth",
        image: "assets/images/jesus.png",
        about: "Jesus of Nazareth, central to Christianity, is believed by Christians to be the Son of God and the Messiah. Born around 4 BCE in Bethlehem, Jesus preached about the Kingdom of God, love, and forgiveness. His teachings, recorded in the Gospels, emphasize compassion, humility, and faith. He performed miracles, was crucified, and, according to Christian belief, resurrected, offering salvation to believers. Jesus' life and message of redemption, hope, and the transformative power of love have profoundly shaped Western civilization and continue to inspire millions worldwide."
    },
    {
        name: "Harriet Tubman",
        image: "assets/images/tubman.png",
        about: "Harriet Tubman, born around 1822, was an American abolitionist and key figure in the Underground Railroad. Escaping from slavery, she made 13 missions to rescue around 70 enslaved people, earning the nickname 'Moses.' During the Civil War, Tubman served as a scout, nurse, and spy for the Union Army, contributing to military campaigns that freed hundreds of slaves. Her bravery, strategic acumen, and dedication to freedom and equality made her an enduring symbol of resistance and human rights. Tubman's legacy continues to inspire generations fighting for justice, liberty, and civil rights."
    },
    {
        name: "Mahatma Gandhi",
        image: "assets/images/gandhi.png",
        about: "Mahatma Gandhi, born in 1869, was an Indian leader and advocate for nonviolent resistance. He led the Indian independence movement against British rule, employing principles of nonviolence and civil disobedience. Gandhi's campaigns, such as the Salt March and Quit India Movement, mobilized millions and significantly contributed to India's independence in 1947. His philosophy of Satyagraha inspired global civil rights movements, advocating for justice, peace, and human rights. Gandhi's legacy as a symbol of peace, justice, and nonviolent resistance remains influential worldwide, inspiring leaders and movements for social change."
    },
    {
        name: "Nelson Mandela",
        image: "assets/images/mandela.png",
        about: "Nelson Mandela, born in 1918, was a South African anti-apartheid revolutionary and the first black president of South Africa. Imprisoned for 27 years for his activism, Mandela became a global symbol of resistance and reconciliation. After his release, he led negotiations to dismantle apartheid and establish multiracial elections, resulting in his presidency in 1994. Mandela's emphasis on forgiveness, unity, and human rights, along with his work for social justice, has made him an enduring icon of peace and equality, inspiring leaders and movements worldwide."
    },
    {
        name: "Martin Luther King Jr.",
        image: "assets/images/king.png",
        about: "Martin Luther King Jr., born in 1929, was an American civil rights leader and Baptist minister. He advocated for racial equality through nonviolent resistance, leading key movements like the Montgomery Bus Boycott and the March on Washington. King's 'I Have a Dream' speech articulated his vision of racial harmony and justice. Awarded the Nobel Peace Prize in 1964, his efforts were crucial in advancing civil rights legislation. King's legacy of justice, peace, and equality continues to inspire globally, making him a central figure in the struggle for human rights."
    },
    {
        name: "Karl Marx",
        image: "assets/images/marx.png",
        about: "Karl Marx, born in 1818, was a German philosopher, economist, and revolutionary socialist. He co-authored 'The Communist Manifesto' with Friedrich Engels, outlining the principles of Marxism, and wrote 'Das Kapital,' analyzing the dynamics of capitalism. Marx's theories about class struggle, the exploitation inherent in capitalism, and the eventual rise of a classless society have profoundly influenced political movements and intellectual thought. Despite controversies, Marx's critique of capitalism and vision of socialism continue to shape discussions on economics, politics, and social justice, making him a pivotal figure in modern history."
    },
    {
        name: "Maya Angelou",
        image: "assets/images/angelou.png",
        about: "Maya Angelou, born in 1928, was an American poet, memoirist, and civil rights activist. Her autobiographical work, 'I Know Why the Caged Bird Sings,' explores themes of identity, racism, and resilience, breaking new ground in American literature. Angelou's poetry and prose celebrate the strength and dignity of the human spirit. She worked with civil rights leaders like Martin Luther King Jr. and Malcolm X, using her voice to advocate for social justice. Angelou's literary and activist legacy continues to inspire, highlighting the power of personal narrative and the fight for equality."
    },
    {
        name: "William Shakespeare",
        image: "assets/images/shakespeare.png",
        about: "William Shakespeare, born in 1564, was an English playwright and poet, regarded as the greatest writer in the English language. His works include 39 plays, 154 sonnets, and numerous poems, covering genres like tragedy, comedy, and history. Masterpieces such as 'Hamlet,' 'Othello,' 'King Lear,' and 'Macbeth' explore complex human emotions and societal issues with unparalleled depth and linguistic brilliance. Shakespeare's profound influence on literature, language, and the arts endures, with his plays continuously performed and studied worldwide for their timeless themes, rich characterizations, and innovative use of language."
    },
    {
        name: "Johann Wolfgang von Goethe",
        image: "assets/images/goethe.png",
        about: "Johann Wolfgang von Goethe was a German writer, poet, and polymath born in 1749. Known for his literary masterpieces such as 'Faust' and 'The Sorrows of Young Werther,' Goethe's works explore themes of love, ambition, and the human condition. A leading figure in the Sturm und Drang movement, he also made significant contributions to science, particularly in color theory and the study of plant morphology. Goethe's influence on literature, philosophy, and science is profound, marking him as one of the greatest figures in Western intellectual history and a pioneer in the integration of arts and sciences."
    },
    {
        name: "Jane Austen",
        image: "assets/images/austen.png",
        about: "Jane Austen, born in 1775, was an English novelist known for her keen social commentary and exploration of domestic life. Her novels, including 'Pride and Prejudice,' 'Sense and Sensibility,' and 'Emma,' examine the lives and manners of the British landed gentry with wit, insight, and irony. Austen's sharp observations on social class, marriage, and morality, along with her memorable characters and innovative use of free indirect discourse, have made her works enduring classics in English literature. They continue to be celebrated for their narrative brilliance, depth, and timeless exploration of human relationships."
    },
    {
        name: "Emily Dickinson",
        image: "assets/images/dickinson.png",
        about: "Emily Dickinson, born in 1830, was an American poet known for her unique and innovative style. Living much of her life in seclusion, she wrote nearly 1,800 poems exploring themes of nature, death, love, and immortality. Dickinson's work, characterized by its brevity, unconventional punctuation, and profound depth, was largely unpublished during her lifetime. Posthumously, her poetry gained acclaim for its introspective and lyrical quality. Her distinctive voice and exploration of existential themes have established her as one of the most important figures in American literature, influencing countless poets and writers."
    },
    {
        name: "Virginia Woolf",
        image: "assets/images/woolf.png",
        about: "Virginia Woolf, born in 1882, was an English writer and modernist whose innovative narrative techniques transformed literature. Her novels, including 'Mrs Dalloway' and 'To the Lighthouse,' use stream-of-consciousness to explore characters' inner lives and perceptions of reality. Woolf's essays, such as 'A Room of One's Own,' advocate for women's intellectual freedom and rights, addressing the systemic barriers faced by female writers. A central figure in the Bloomsbury Group, Woolf's work continues to influence contemporary literature and feminist thought, highlighting the complexities of identity, time, and existence."
    },
    {
        name: "Leonardo da Vinci",
        image: "assets/images/leonardo.png",
        about: "Leonardo da Vinci was an Italian polymath of the Renaissance, celebrated for his contributions to art, science, and engineering. Born in 1452, his masterpieces like the 'Mona Lisa' and 'The Last Supper' showcase his artistic genius and mastery of techniques like sfumato. Leonardo's extensive notebooks reveal his curiosity and foresight in anatomy, mechanics, and flight. He epitomized the Renaissance ideal of the 'universal man,' blending art and science seamlessly. His interdisciplinary approach and relentless curiosity continue to inspire, marking him as one of history's greatest minds with a lasting legacy in both the arts and sciences."
    },
    {
        name: "Frida Kahlo",
        image: "assets/images/kahlo.png",
        about: "Frida Kahlo, born in 1907, was a Mexican painter known for her vivid, emotive works that explore themes of identity, pain, and cultural heritage. Her art, characterized by its surrealist elements and autobiographical content, includes notable works like 'The Two Fridas' and 'Self-Portrait with Thorn Necklace and Hummingbird.' Despite a life marked by physical suffering due to a bus accident, Kahlo's innovative style and powerful storytelling have made her an iconic figure in 20th-century art. Her exploration of personal and cultural identity continues to resonate, influencing artists and audiences worldwide."
    },
    {
        name: "Ada Lovelace",
        image: "assets/images/lovelace.png",
        about: "Ada Lovelace, born in 1815, was an English mathematician and writer, often considered the world's first computer programmer. She is best known for her work on Charles Babbage's Analytical Engine, where she developed the first algorithm intended to be processed by a machine. Lovelace's foresight about the potential of computing extended beyond calculation to broader applications, including music and graphics. Her contributions laid the groundwork for modern computer science, and she is celebrated as a pioneer in the field, inspiring generations of scientists and engineers with her visionary insights and innovative thinking."
    },
    {
        name: "Wolfgang Amadeus Mozart",
        image: "assets/images/mozart.png",
        about: "Wolfgang Amadeus Mozart, born in 1756, was an Austrian composer and a prodigious musical talent. His extensive body of work includes symphonies, operas, chamber music, and piano concertos. Renowned compositions like 'The Magic Flute,' 'Don Giovanni,' and 'The Marriage of Figaro' showcase his mastery of melody, form, and emotional depth. Despite a life marked by financial difficulties, Mozart's innovative approach to music and his enduring masterpieces have left an indelible mark on Western classical music, influencing countless composers and continuing to captivate audiences worldwide with their beauty and complexity."
    },
    {
        name: "Albert Einstein",
        image: "assets/images/einstein.png",
        about: "Albert Einstein, born in 1879, was a theoretical physicist whose work revolutionized our understanding of the universe. Best known for his theories of relativity and the equation E=mc², Einstein's contributions include the photoelectric effect, which supported quantum theory. His work laid the foundation for modern physics, influencing the development of various scientific fields. A Nobel Prize laureate, Einstein was also an advocate for civil rights and pacifism. His intellectual legacy and impact on science and society remain unparalleled, with his theories continuing to inspire and shape scientific research and thought."
    },
    {
        name: "Galileo Galilei",
        image: "assets/images/galileo.png",
        about: "Galileo Galilei, an Italian astronomer and physicist born in 1564, was pivotal in the Scientific Revolution. He improved the telescope, made groundbreaking astronomical observations like the moons of Jupiter and the phases of Venus, and supported the heliocentric model of the solar system. Galileo's findings challenged the geocentric model and faced opposition from the Catholic Church, leading to his trial and house arrest. His work laid the foundation for modern physics and astronomy, emphasizing observation, experimentation, and the scientific method. Galileo's legacy as a pioneer of scientific inquiry remains influential and enduring."
    },
    {
        name: "Meister Eckhart",
        image: "assets/images/eckhart.png",
        about: "Meister Eckhart was a German theologian and mystic, known for his deep spiritual insights and influential teachings. Born around 1260, he joined the Dominican Order and became renowned for his sermons and writings. Eckhart emphasized the concept of 'Gelassenheit' (detachment) and the 'divine spark' within each person. His teachings on the direct experience of God and inner spiritual transformation were controversial, leading to accusations of heresy. Despite this, Eckhart's profound influence endures, inspiring mystics, theologians, and seekers of spiritual wisdom with his exploration of the divine and the human soul."
    }
];

const HISTORY_PREFIX = 'sageStories_history_';

export const saveHistory = (figureName, history) => {
  localStorage.setItem(`${HISTORY_PREFIX}${figureName}`, JSON.stringify(history));
};

export const getHistory = (figureName) => {
  const history = localStorage.getItem(`${HISTORY_PREFIX}${figureName}`);
  return history ? JSON.parse(history) : [];
};

export const clearHistory = (figureName) => {
  localStorage.removeItem(`${HISTORY_PREFIX}${figureName}`);
};

export const clearAllHistory = () => {
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith(HISTORY_PREFIX)) {
      localStorage.removeItem(key);
    }
  });
};
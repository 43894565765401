import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px;
  box-sizing: border-box;
`;

export const StarMapGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
`;

export const StarItem = styled.div`
  background-color: #555;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;

  &:hover {
    transform: scale(1.05);
  }

  &.gathered {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
`;

export const ControlButton = styled.button`
  padding: 10px 20px;
  background-color: #C4972C;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #E97451;
  }
`;

export const CloseButton = styled(ControlButton)`
  align-self: flex-end;
  margin-top: 20px;
  background-color: #E97451;

  &:hover {
    background-color: #C4972C;
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 20px;
  background-color: #555;
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #C4972C;
  transition: width 0.5s ease-in-out;
`;
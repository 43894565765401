// FigureCarousel.js
import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { historicalFigures } from '../api/figures';
import WisdomMapModal from './WisdomMapModal';
import './FigureCarousel.css';

const FigureCarousel = ({ isOpen, onClose, onSelectFigure, setShowHamburgerMenu = () => {} }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCategory, setCurrentCategory] = useState('Philosophers');
  const [showFullInfo, setShowFullInfo] = useState(false);
  const [showWisdom, setShowWisdom] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setShowHamburgerMenu(false);
    }
    return () => setShowHamburgerMenu(true);
  }, [isOpen, setShowHamburgerMenu]);

  const categories = {
    Philosophers: ['Laozi', 'Siddhartha Gautama', 'Plato', 'Arthur Schopenhauer', 'Friedrich Nietzsche', 'Carl Gustav Jung', 'Marcus Aurelius', 'Rumi', 'Joseph Campbell', 'Dōgen Zenji'],
    Reformers: ['Jesus of Nazareth', 'Harriet Tubman', 'Mahatma Gandhi', 'Nelson Mandela', 'Simone de Beauvoir', 'Meister Eckhart', 'Martin Luther King Jr.', 'Galileo Galilei', 'Karl Marx', 'Maya Angelou'],
    Creators: ['William Shakespeare', 'Johann Wolfgang von Goethe', 'Jane Austen', 'Emily Dickinson', 'Virginia Woolf', 'Leonardo da Vinci', 'Frida Kahlo', 'Ada Lovelace', 'Wolfgang Amadeus Mozart', 'Albert Einstein'],
  };

  const figures = historicalFigures.filter(figure => categories[currentCategory].includes(figure.name));

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentIndex((prevIndex) => (prevIndex + 1) % figures.length),
    onSwipedRight: () => setCurrentIndex((prevIndex) => (prevIndex - 1 + figures.length) % figures.length),
    onSwipedUp: () => setShowFullInfo(true),
    onSwipedDown: () => setShowFullInfo(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const currentFigure = figures[currentIndex];

  const handleSelect = () => {
    if (onSelectFigure) {
      onSelectFigure(currentFigure);
    } else {
      console.error('onSelectFigure is not defined');
    }
  };

  if (!isOpen) return null;

  const renderCategories = () => (
    <div className="categories-section">
      {Object.keys(categories).map(category => (
        <button
          key={category}
          className={`category-button ${currentCategory === category ? 'active' : ''}`}
          onClick={() => setCurrentCategory(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );

  const renderMiniatures = () => (
    <div className="miniatures-section">
      {figures.map((figure, index) => (
        <div
          key={index}
          className={`miniature ${index === currentIndex ? 'active' : ''}`}
          onClick={() => setCurrentIndex(index)}
        >
          <img src={require(`../assets/miniatures/${figure.name.toLowerCase().split(' ').pop()}.webp`)} alt={figure.name} />
        </div>
      ))}
    </div>
  );

  const renderMainImage = () => (
    <div className="main-image-section">
      <div className="figure-image" style={{ backgroundImage: `url(${require(`../assets/images/${currentFigure.name.toLowerCase().split(' ').pop()}${windowWidth < 768 ? '_1200' : '_2400'}.png`)})` }}>
        <div className="info-overlay" onClick={() => setShowFullInfo(!showFullInfo)}>
          <h2>{currentFigure.name}</h2>
          {showFullInfo && <p>{currentFigure.about}</p>}
        </div>
      </div>
    </div>
  );

  const renderActions = () => (
    <div className="actions-section">
      <button onClick={() => setShowFullInfo(!showFullInfo)}>About</button>
      <button onClick={handleSelect} className="select-button">Select</button>
      <button onClick={() => setShowWisdom(true)}>Wisdom</button>
    </div>
  );

  return (
    <>
      <div className={`figure-carousel ${windowWidth >= 1601 ? 'extra-large-screen' : ''}`} {...handlers}>
        {windowWidth < 768 && (
          <>
            {renderCategories()}
            {renderMiniatures()}
            {renderMainImage()}
            {renderActions()}
          </>
        )}
        {windowWidth >= 768 && windowWidth < 1024 && (
          <>
            {renderCategories()}
            <div className="tablet-layout">
              {renderMiniatures()}
              <div className="tablet-main-content">
                {renderMainImage()}
                {renderActions()}
              </div>
            </div>
          </>
        )}
        {windowWidth >= 1024 && (
          <div className="desktop-layout">
            <div className="left-sidebar">
              {renderCategories()}
              {renderMiniatures()}
            </div>
            <div className="main-content">
              {renderMainImage()}
            </div>
            <div className="right-sidebar">
              {renderActions()}
            </div>
          </div>
        )}
      </div>
      {showWisdom && (
        <WisdomMapModal
          isOpen={showWisdom}
          onClose={() => setShowWisdom(false)}
          selectedFigure={currentFigure}
        />
      )}
    </>
  );
};

export default FigureCarousel;

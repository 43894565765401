export const seedsOfWisdom = [
    // Philosophers: Laozi
    { category: 'Philosophers', name: 'Laozi - The Tao (The Way)', gathered: true },
    { category: 'Philosophers', name: 'Laozi - Wu Wei (Non-action)', gathered: false },
    { category: 'Philosophers', name: 'Laozi - Simplicity and Humility', gathered: true },
    { category: 'Philosophers', name: 'Laozi - Te (Virtue)', gathered: false },
    { category: 'Philosophers', name: 'Laozi - Balance and Harmony (Yin and Yang)', gathered: true },
    { category: 'Philosophers', name: 'Laozi - Living in Harmony with Nature', gathered: false },
    { category: 'Philosophers', name: 'Laozi - Spiritual Insight and Enlightenment', gathered: true },
    { category: 'Philosophers', name: 'Laozi - Leadership through Non-interference', gathered: false },
    { category: 'Philosophers', name: 'Laozi - Ecological Harmony', gathered: true },
    { category: 'Philosophers', name: 'Laozi - Mind-Body Connection', gathered: false },

    // Philosophers: Siddhartha Gautama (Buddha)
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - The Four Noble Truths', gathered: true },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - The Eightfold Path', gathered: false },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - Mindfulness', gathered: true },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - Compassion and Loving-kindness', gathered: false },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - The Nature of Suffering', gathered: true },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - Impermanence', gathered: false },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - Karma and Rebirth', gathered: true },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - Meditation Practices', gathered: false },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - The Middle Way', gathered: true },
    { category: 'Philosophers', name: 'Siddhartha Gautama (Buddha) - Enlightenment', gathered: false },

    // Philosophers: Plato
    { category: 'Philosophers', name: 'Plato - The Forms', gathered: true },
    { category: 'Philosophers', name: 'Plato - The Allegory of the Cave', gathered: false },
    { category: 'Philosophers', name: 'Plato - The Philosopher King', gathered: true },
    { category: 'Philosophers', name: 'Plato - The Ideal State', gathered: false },
    { category: 'Philosophers', name: 'Plato - Justice', gathered: true },
    { category: 'Philosophers', name: 'Plato - Ethical and Moral Reasoning', gathered: false },
    { category: 'Philosophers', name: 'Plato - The Tripartite Soul', gathered: true },
    { category: 'Philosophers', name: 'Plato - Virtue Ethics', gathered: false },
    { category: 'Philosophers', name: 'Plato - The Dialectic Method', gathered: true },
    { category: 'Philosophers', name: 'Plato - Knowledge as Recollection', gathered: false },

    // Philosophers: Arthur Schopenhauer
    { category: 'Philosophers', name: 'Arthur Schopenhauer - The Will to Live', gathered: true },
    { category: 'Philosophers', name: 'Arthur Schopenhauer - The Nature of Suffering', gathered: false },
    { category: 'Philosophers', name: 'Arthur Schopenhauer - Pessimism', gathered: true },
    { category: 'Philosophers', name: 'Arthur Schopenhauer - Aesthetics and Art', gathered: false },
    { category: 'Philosophers', name: 'Arthur Schopenhauer - Compassion as Moral Basis', gathered: true },
    { category: 'Philosophers', name: 'Arthur Schopenhauer - Asceticism', gathered: false },
    { category: 'Philosophers', name: 'Arthur Schopenhauer - Influence of Eastern Philosophy', gathered: true },
    { category: 'Philosophers', name: 'Arthur Schopenhauer - The Role of Music', gathered: false },

    // Philosophers: Friedrich Nietzsche
    { category: 'Philosophers', name: 'Friedrich Nietzsche - The Will to Power', gathered: true },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - The Übermensch', gathered: false },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - Eternal Recurrence', gathered: true },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - Master-Slave Morality', gathered: false },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - The Death of God', gathered: true },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - Nihilism', gathered: false },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - The Apollonian and Dionysian', gathered: true },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - Perspectivism', gathered: false },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - Beyond Good and Evil', gathered: true },
    { category: 'Philosophers', name: 'Friedrich Nietzsche - The Gay Science', gathered: false },

    // Philosophers: Carl Gustav Jung
    { category: 'Philosophers', name: 'Carl Gustav Jung - Individuation', gathered: true },
    { category: 'Philosophers', name: 'Carl Gustav Jung - The Collective Unconscious', gathered: false },
    { category: 'Philosophers', name: 'Carl Gustav Jung - Archetypes', gathered: true },
    { category: 'Philosophers', name: 'Carl Gustav Jung - The Shadow', gathered: false },
    { category: 'Philosophers', name: 'Carl Gustav Jung - Anima/Animus', gathered: true },
    { category: 'Philosophers', name: 'Carl Gustav Jung - Symbolism and Alchemy', gathered: false },
    { category: 'Philosophers', name: 'Carl Gustav Jung - Dream Analysis', gathered: true },
    { category: 'Philosophers', name: 'Carl Gustav Jung - Synchronicity', gathered: false },
    { category: 'Philosophers', name: 'Carl Gustav Jung - Mythology and Cultural Context', gathered: true },
    { category: 'Philosophers', name: 'Carl Gustav Jung - Psychological Types', gathered: false },

    // Philosophers: Simone de Beauvoir
    { category: 'Philosophers', name: 'Simone de Beauvoir - The Second Sex', gathered: true },
    { category: 'Philosophers', name: 'Simone de Beauvoir - Feminist Philosophy', gathered: false },
    { category: 'Philosophers', name: 'Simone de Beauvoir - Existentialist Ethics', gathered: true },
    { category: 'Philosophers', name: 'Simone de Beauvoir - Freedom and Responsibility', gathered: false },
    { category: 'Philosophers', name: 'Simone de Beauvoir - Ambiguity of the Human Condition', gathered: true },
    { category: 'Philosophers', name: 'Simone de Beauvoir - Emotional Intelligence', gathered: false },
    { category: 'Philosophers', name: 'Simone de Beauvoir - Political Commitment', gathered: true },
    { category: 'Philosophers', name: 'Simone de Beauvoir - The Ethics of Ambiguity', gathered: false },
    { category: 'Philosophers', name: 'Simone de Beauvoir - Literature and Philosophy', gathered: true },
    { category: 'Philosophers', name: 'Simone de Beauvoir - The Other', gathered: false },

    // Philosophers: Marcus Aurelius
    { category: 'Philosophers', name: 'Marcus Aurelius - Stoicism', gathered: true },
    { category: 'Philosophers', name: 'Marcus Aurelius - Meditations', gathered: false },
    { category: 'Philosophers', name: 'Marcus Aurelius - Virtue and Duty', gathered: true },
    { category: 'Philosophers', name: 'Marcus Aurelius - Acceptance of Fate', gathered: false },
    { category: 'Philosophers', name: 'Marcus Aurelius - Rationality and Wisdom', gathered: true },
    { category: 'Philosophers', name: 'Marcus Aurelius - Inner Strength and Resilience', gathered: false },
    { category: 'Philosophers', name: 'Marcus Aurelius - Service to Others', gathered: true },

    // Philosophers: Rumi
    { category: 'Philosophers', name: 'Rumi - Divine Love', gathered: true },
    { category: 'Philosophers', name: 'Rumi - The Spiritual Journey', gathered: false },
    { category: 'Philosophers', name: 'Rumi - Unity with the Divine', gathered: true },
    { category: 'Philosophers', name: 'Rumi - Sufism', gathered: false },
    { category: 'Philosophers', name: 'Rumi - The Heart’s Intuition', gathered: true },
    { category: 'Philosophers', name: 'Rumi - The Mystic’s Path', gathered: false },
    { category: 'Philosophers', name: 'Rumi - The Power of Poetry', gathered: true },

    // Philosophers: Joseph Campbell
    { category: 'Philosophers', name: 'Joseph Campbell - The Hero’s Journey', gathered: true },
    { category: 'Philosophers', name: 'Joseph Campbell - The Monomyth', gathered: false },
    { category: 'Philosophers', name: 'Joseph Campbell - The Power of Myth', gathered: true },
    { category: 'Philosophers', name: 'Joseph Campbell - Archetypes in Mythology', gathered: false },
    { category: 'Philosophers', name: 'Joseph Campbell - Cross-Cultural Myths', gathered: true },
    { category: 'Philosophers', name: 'Joseph Campbell - The Inner Quest', gathered: false },
    { category: 'Philosophers', name: 'Joseph Campbell - The Function of Myth', gathered: true },
    { category: 'Philosophers', name: 'Joseph Campbell - Transformation and Renewal', gathered: false },
    { category: 'Philosophers', name: 'Joseph Campbell - Myth and Modern Life', gathered: true },
    { category: 'Philosophers', name: 'Joseph Campbell - The Call to Adventure', gathered: false },

    // Philosophers: Dōgen Zenji
    { category: 'Philosophers', name: 'Dōgen Zenji - Zazen (Seated Meditation)', gathered: true },
    { category: 'Philosophers', name: 'Dōgen Zenji - Zen Practice', gathered: false },
    { category: 'Philosophers', name: 'Dōgen Zenji - The Nature of Reality', gathered: true },
    { category: 'Philosophers', name: 'Dōgen Zenji - Impermanence', gathered: false },
    { category: 'Philosophers', name: 'Dōgen Zenji - The Importance of Now', gathered: true },
    { category: 'Philosophers', name: 'Dōgen Zenji - The Practice of Non-Attachment', gathered: false },
    { category: 'Philosophers', name: 'Dōgen Zenji - The Interconnectedness of All Things', gathered: true },

    // Reformers: Jesus of Nazareth
    { category: 'Reformers', name: 'Jesus of Nazareth - Love Thy Neighbor', gathered: true },
    { category: 'Reformers', name: 'Jesus of Nazareth - Compassion and Healing', gathered: false },
    { category: 'Reformers', name: 'Jesus of Nazareth - The Power of Faith', gathered: true },
    { category: 'Reformers', name: 'Jesus of Nazareth - The Kingdom of God', gathered: false },
    { category: 'Reformers', name: 'Jesus of Nazareth - Forgiveness and Mercy', gathered: true },
    { category: 'Reformers', name: 'Jesus of Nazareth - The Golden Rule', gathered: false },
    { category: 'Reformers', name: 'Jesus of Nazareth - The Beatitudes', gathered: true },
    { category: 'Reformers', name: 'Jesus of Nazareth - The Sermon on the Mount', gathered: false },
    { category: 'Reformers', name: 'Jesus of Nazareth - Parables and Teachings', gathered: true },
    { category: 'Reformers', name: 'Jesus of Nazareth - Resurrection and Eternal Life', gathered: false },

    // Reformers: Harriet Tubman
    { category: 'Reformers', name: 'Harriet Tubman - The Quest for Freedom', gathered: true },
    { category: 'Reformers', name: 'Harriet Tubman - Courage and Bravery', gathered: false },
    { category: 'Reformers', name: 'Harriet Tubman - The Underground Railroad', gathered: true },
    { category: 'Reformers', name: 'Harriet Tubman - Leadership in Adversity', gathered: false },
    { category: 'Reformers', name: 'Harriet Tubman - Perseverance and Determination', gathered: true },
    { category: 'Reformers', name: 'Harriet Tubman - Empowerment of the Oppressed', gathered: false },
    { category: 'Reformers', name: 'Harriet Tubman - Civil Rights Advocacy', gathered: true },
    { category: 'Reformers', name: 'Harriet Tubman - The Power of Faith', gathered: false },
    { category: 'Reformers', name: 'Harriet Tubman - The Value of Sacrifice', gathered: true },
    { category: 'Reformers', name: 'Harriet Tubman - Legacy of Liberation', gathered: false },

    // Reformers: Mahatma Gandhi
    { category: 'Reformers', name: 'Mahatma Gandhi - Nonviolent Resistance (Ahimsa)', gathered: true },
    { category: 'Reformers', name: 'Mahatma Gandhi - Truth and Satyagraha', gathered: false },
    { category: 'Reformers', name: 'Mahatma Gandhi - Civil Disobedience', gathered: true },
    { category: 'Reformers', name: 'Mahatma Gandhi - Unity and Tolerance', gathered: false },
    { category: 'Reformers', name: 'Mahatma Gandhi - Self-Discipline and Fasting', gathered: true },
    { category: 'Reformers', name: 'Mahatma Gandhi - Swaraj (Self-Rule)', gathered: false },
    { category: 'Reformers', name: 'Mahatma Gandhi - Ethical Living', gathered: true },
    { category: 'Reformers', name: 'Mahatma Gandhi - The Power of Community', gathered: false },
    { category: 'Reformers', name: 'Mahatma Gandhi - Simplicity and Minimalism', gathered: true },
    { category: 'Reformers', name: 'Mahatma Gandhi - Legacy of Peace', gathered: false },

    // Reformers: Nelson Mandela
    { category: 'Reformers', name: 'Nelson Mandela - The Fight Against Apartheid', gathered: true },
    { category: 'Reformers', name: 'Nelson Mandela - Reconciliation and Forgiveness', gathered: false },
    { category: 'Reformers', name: 'Nelson Mandela - Human Rights and Equality', gathered: true },
    { category: 'Reformers', name: 'Nelson Mandela - Perseverance and Resilience', gathered: false },
    { category: 'Reformers', name: 'Nelson Mandela - Leadership and Integrity', gathered: true },
    { category: 'Reformers', name: 'Nelson Mandela - Unity in Diversity', gathered: false },
    { category: 'Reformers', name: 'Nelson Mandela - The Importance of Dialogue', gathered: true },
    { category: 'Reformers', name: 'Nelson Mandela - The Role of Justice', gathered: false },
    { category: 'Reformers', name: 'Nelson Mandela - The Power of Education', gathered: true },
    { category: 'Reformers', name: 'Nelson Mandela - Legacy of Freedom', gathered: false },

    // Reformers: Martin Luther King Jr.
    { category: 'Reformers', name: 'Martin Luther King Jr. - The Dream of Equality', gathered: true },
    { category: 'Reformers', name: 'Martin Luther King Jr. - Nonviolent Protest', gathered: false },
    { category: 'Reformers', name: 'Martin Luther King Jr. - Civil Rights Movement', gathered: true },
    { category: 'Reformers', name: 'Martin Luther King Jr. - Justice and Injustice', gathered: false },
    { category: 'Reformers', name: 'Martin Luther King Jr. - The Power of Love', gathered: true },
    { category: 'Reformers', name: 'Martin Luther King Jr. - The Beloved Community', gathered: false },
    { category: 'Reformers', name: 'Martin Luther King Jr. - The March on Washington', gathered: true },
    { category: 'Reformers', name: 'Martin Luther King Jr. - The Importance of Faith', gathered: false },
    { category: 'Reformers', name: 'Martin Luther King Jr. - The Role of the Church', gathered: true },
    { category: 'Reformers', name: 'Martin Luther King Jr. - Legacy of Courage', gathered: false },

    // Reformers: Karl Marx
    { category: 'Reformers', name: 'Karl Marx - Class Struggle', gathered: true },
    { category: 'Reformers', name: 'Karl Marx - Historical Materialism', gathered: false },
    { category: 'Reformers', name: 'Karl Marx - The Critique of Capitalism', gathered: true },
    { category: 'Reformers', name: 'Karl Marx - The Theory of Surplus Value', gathered: false },
    { category: 'Reformers', name: 'Karl Marx - Alienation and Exploitation', gathered: true },
    { category: 'Reformers', name: 'Karl Marx - The Proletariat and Bourgeoisie', gathered: false },
    { category: 'Reformers', name: 'Karl Marx - Revolutionary Change', gathered: true },
    { category: 'Reformers', name: 'Karl Marx - Communism and Socialism', gathered: false },
    { category: 'Reformers', name: 'Karl Marx - The Manifesto of the Communist Party', gathered: true },
    { category: 'Reformers', name: 'Karl Marx - Legacy of Marxist Theory', gathered: false },

    // Reformers: Maya Angelou
    { category: 'Reformers', name: 'Maya Angelou - The Power of Words', gathered: true },
    { category: 'Reformers', name: 'Maya Angelou - Overcoming Adversity', gathered: false },
    { category: 'Reformers', name: 'Maya Angelou - Racism and Social Injustice', gathered: true },
    { category: 'Reformers', name: 'Maya Angelou - Resilience and Strength', gathered: false },
    { category: 'Reformers', name: 'Maya Angelou - The Importance of Identity', gathered: true },
    { category: 'Reformers', name: 'Maya Angelou - Feminism and Empowerment', gathered: false },
    { category: 'Reformers', name: 'Maya Angelou - The Beauty of Poetry', gathered: true },
    { category: 'Reformers', name: 'Maya Angelou - Civil Rights Advocacy', gathered: false },
    { category: 'Reformers', name: 'Maya Angelou - The Role of the Arts', gathered: true },
    { category: 'Reformers', name: 'Maya Angelou - Legacy of Inspiration', gathered: false },

    // Creators: William Shakespeare
    { category: 'Creators', name: 'William Shakespeare - The Human Condition', gathered: true },
    { category: 'Creators', name: 'William Shakespeare - The Complexity of Human Emotions', gathered: false },
    { category: 'Creators', name: 'William Shakespeare - Tragedy and Comedy', gathered: true },
    { category: 'Creators', name: 'William Shakespeare - The Nature of Power', gathered: false },
    { category: 'Creators', name: 'William Shakespeare - Love and Relationships', gathered: true },
    { category: 'Creators', name: 'William Shakespeare - The Role of Fate', gathered: false },
    { category: 'Creators', name: 'William Shakespeare - The Art of Storytelling', gathered: true },
    { category: 'Creators', name: 'William Shakespeare - The Beauty of Language', gathered: false },
    { category: 'Creators', name: 'William Shakespeare - The Supernatural', gathered: true },
    { category: 'Creators', name: 'William Shakespeare - Shakespeare\'s Literary Legacy', gathered: false },

    // Creators: Johann Wolfgang von Goethe
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Search for Knowledge', gathered: true },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Interplay of Light and Darkness', gathered: false },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - Romanticism and Nature', gathered: true },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Role of the Individual', gathered: false },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Struggle for Freedom', gathered: true },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Importance of Art', gathered: false },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Nature of Good and Evil', gathered: true },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Power of Love', gathered: false },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - The Beauty of Language', gathered: true },
    { category: 'Creators', name: 'Johann Wolfgang von Goethe - Goethe\'s Literary Legacy', gathered: false },

        // Creators: Jane Austen
        { category: 'Creators', name: 'Jane Austen - Social Commentary', gathered: true },
        { category: 'Creators', name: 'Jane Austen - The Role of Women', gathered: false },
        { category: 'Creators', name: 'Jane Austen - Marriage and Relationships', gathered: true },
        { category: 'Creators', name: 'Jane Austen - Class and Society', gathered: false },
        { category: 'Creators', name: 'Jane Austen - The Importance of Morality', gathered: true },
        { category: 'Creators', name: 'Jane Austen - The Complexity of Human Emotions', gathered: false },
        { category: 'Creators', name: 'Jane Austen - Wit and Irony', gathered: true },
        { category: 'Creators', name: 'Jane Austen - The Art of Storytelling', gathered: false },
        { category: 'Creators', name: 'Jane Austen - The Beauty of Language', gathered: true },
        { category: 'Creators', name: 'Jane Austen - Austen\'s Literary Legacy', gathered: false },
    
        // Creators: Emily Dickinson
        { category: 'Creators', name: 'Emily Dickinson - The Inner Life', gathered: true },
        { category: 'Creators', name: 'Emily Dickinson - The Nature of Death', gathered: false },
        { category: 'Creators', name: 'Emily Dickinson - The Beauty of Nature', gathered: true },
        { category: 'Creators', name: 'Emily Dickinson - The Power of Solitude', gathered: false },
        { category: 'Creators', name: 'Emily Dickinson - The Complexity of Human Emotions', gathered: true },
        { category: 'Creators', name: 'Emily Dickinson - The Art of Poetry', gathered: false },
        { category: 'Creators', name: 'Emily Dickinson - The Role of Imagination', gathered: true },
        { category: 'Creators', name: 'Emily Dickinson - The Importance of Individuality', gathered: false },
        { category: 'Creators', name: 'Emily Dickinson - The Search for Meaning', gathered: true },
        { category: 'Creators', name: 'Emily Dickinson - Dickinson\'s Literary Legacy', gathered: false },
    
        // Creators: Virginia Woolf
        { category: 'Creators', name: 'Virginia Woolf - The Stream of Consciousness', gathered: true },
        { category: 'Creators', name: 'Virginia Woolf - The Role of Women', gathered: false },
        { category: 'Creators', name: 'Virginia Woolf - The Nature of Time', gathered: true },
        { category: 'Creators', name: 'Virginia Woolf - The Complexity of Human Emotions', gathered: false },
        { category: 'Creators', name: 'Virginia Woolf - The Art of Storytelling', gathered: true },
        { category: 'Creators', name: 'Virginia Woolf - The Power of Imagination', gathered: false },
        { category: 'Creators', name: 'Virginia Woolf - The Importance of Individuality', gathered: true },
        { category: 'Creators', name: 'Virginia Woolf - The Search for Meaning', gathered: false },
        { category: 'Creators', name: 'Virginia Woolf - The Beauty of Language', gathered: true },
        { category: 'Creators', name: 'Virginia Woolf - Woolf\'s Literary Legacy', gathered: false },
    
        // Creators: Leonardo da Vinci
        { category: 'Creators', name: 'Leonardo da Vinci - The Pursuit of Knowledge', gathered: true },
        { category: 'Creators', name: 'Leonardo da Vinci - The Intersection of Art and Science', gathered: false },
        { category: 'Creators', name: 'Leonardo da Vinci - The Importance of Observation', gathered: true },
        { category: 'Creators', name: 'Leonardo da Vinci - The Beauty of Nature', gathered: false },
        { category: 'Creators', name: 'Leonardo da Vinci - The Complexity of Human Anatomy', gathered: true },
        { category: 'Creators', name: 'Leonardo da Vinci - The Art of Innovation', gathered: false },
        { category: 'Creators', name: 'Leonardo da Vinci - The Power of Imagination', gathered: true },
        { category: 'Creators', name: 'Leonardo da Vinci - The Role of the Artist', gathered: false },
        { category: 'Creators', name: 'Leonardo da Vinci - The Search for Truth', gathered: true },
        { category: 'Creators', name: 'Leonardo da Vinci - da Vinci\'s Creative Legacy', gathered: false },
    
        // Creators: Frida Kahlo
        { category: 'Creators', name: 'Frida Kahlo - The Expression of Pain', gathered: true },
        { category: 'Creators', name: 'Frida Kahlo - The Power of Identity', gathered: false },
        { category: 'Creators', name: 'Frida Kahlo - The Role of the Artist', gathered: true },
        { category: 'Creators', name: 'Frida Kahlo - The Beauty of Color', gathered: false },
        { category: 'Creators', name: 'Frida Kahlo - The Complexity of Human Emotions', gathered: true },
        { category: 'Creators', name: 'Frida Kahlo - The Importance of Individuality', gathered: false },
        { category: 'Creators', name: 'Frida Kahlo - The Search for Meaning', gathered: true },
        { category: 'Creators', name: 'Frida Kahlo - The Art of Storytelling', gathered: false },
        { category: 'Creators', name: 'Frida Kahlo - The Power of Imagination', gathered: true },
        { category: 'Creators', name: 'Frida Kahlo - Kahlo\'s Artistic Legacy', gathered: false },
    
        // Creators: Ada Lovelace
        { category: 'Creators', name: 'Ada Lovelace - The Birth of Computing', gathered: true },
        { category: 'Creators', name: 'Ada Lovelace - The Importance of Imagination', gathered: false },
        { category: 'Creators', name: 'Ada Lovelace - The Role of Women in Science', gathered: true },
        { category: 'Creators', name: 'Ada Lovelace - The Power of Mathematics', gathered: false },
        { category: 'Creators', name: 'Ada Lovelace - The Beauty of Logic', gathered: true },
        { category: 'Creators', name: 'Ada Lovelace - The Complexity of Algorithms', gathered: false },
        { category: 'Creators', name: 'Ada Lovelace - The Pursuit of Knowledge', gathered: true },
        { category: 'Creators', name: 'Ada Lovelace - The Intersection of Art and Science', gathered: false },
        { category: 'Creators', name: 'Ada Lovelace - The Vision of Artificial Intelligence', gathered: true },
        { category: 'Creators', name: 'Ada Lovelace - Lovelace\'s Computing Legacy', gathered: false },
    
        // Creators: Wolfgang Amadeus Mozart
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Genius of Composition', gathered: true },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Power of Melody', gathered: false },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Role of Emotion in Music', gathered: true },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Beauty of Harmony', gathered: false },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Importance of Practice', gathered: true },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Complexity of Orchestration', gathered: false },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Influence of Classical Forms', gathered: true },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Art of Improvisation', gathered: false },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - The Legacy of Operatic Works', gathered: true },
        { category: 'Creators', name: 'Wolfgang Amadeus Mozart - Mozart\'s Musical Legacy', gathered: false },
    
        // Creators: Albert Einstein
        { category: 'Creators', name: 'Albert Einstein - The Theory of Relativity', gathered: true },
        { category: 'Creators', name: 'Albert Einstein - The Nature of Space-Time', gathered: false },
        { category: 'Creators', name: 'Albert Einstein - The Importance of Curiosity', gathered: true },
        { category: 'Creators', name: 'Albert Einstein - The Power of Imagination', gathered: false },
        { category: 'Creators', name: 'Albert Einstein - The Role of Thought Experiments', gathered: true },
        { category: 'Creators', name: 'Albert Einstein - The Unity of Physics', gathered: false },
        { category: 'Creators', name: 'Albert Einstein - The Relationship between Energy and Matter', gathered: true },
        { category: 'Creators', name: 'Albert Einstein - The Beauty of Mathematical Formulations', gathered: false },
        { category: 'Creators', name: 'Albert Einstein - The Pursuit of Scientific Truth', gathered: true },
        { category: 'Creators', name: 'Albert Einstein - Einstein\'s Scientific Legacy', gathered: false },
    
        // Creators: Galileo Galilei
        { category: 'Creators', name: 'Galileo Galilei - The Scientific Method', gathered: true },
        { category: 'Creators', name: 'Galileo Galilei - The Importance of Observation', gathered: false },
        { category: 'Creators', name: 'Galileo Galilei - The Role of Experimentation', gathered: true },
        { category: 'Creators', name: 'Galileo Galilei - The Heliocentric Model', gathered: false },
        { category: 'Creators', name: 'Galileo Galilei - The Laws of Motion', gathered: true },
        { category: 'Creators', name: 'Galileo Galilei - The Conflict between Science and Religion', gathered: false },
        { category: 'Creators', name: 'Galileo Galilei - The Power of Inquiry', gathered: true },
        { category: 'Creators', name: 'Galileo Galilei - The Nature of the Universe', gathered: false },
        { category: 'Creators', name: 'Galileo Galilei - The Beauty of Astronomical Discoveries', gathered: true },
        { category: 'Creators', name: 'Galileo Galilei - Galileo\'s Scientific Legacy', gathered: false },
    
        // Creators: Meister Eckhart
        { category: 'Creators', name: 'Meister Eckhart - Mystical Theology', gathered: true },
        { category: 'Creators', name: 'Meister Eckhart - The Nature of God', gathered: false },
        { category: 'Creators', name: 'Meister Eckhart - The Journey of the Soul', gathered: true },
        { category: 'Creators', name: 'Meister Eckhart - The Importance of Inner Experience', gathered: false },
        { category: 'Creators', name: 'Meister Eckhart - The Power of Silence', gathered: true },
        { category: 'Creators', name: 'Meister Eckhart - The Role of Contemplation', gathered: false },
        { category: 'Creators', name: 'Meister Eckhart - The Unity of All Being', gathered: true },
        { category: 'Creators', name: 'Meister Eckhart - The Pursuit of Divine Wisdom', gathered: false },
        { category: 'Creators', name: 'Meister Eckhart - The Relationship between Creator and Creation', gathered: true },
        { category: 'Creators', name: 'Meister Eckhart - Eckhart\'s Mystical Legacy', gathered: false }
];
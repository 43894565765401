// audioservice.js
import OpenAI from 'openai';
import Anthropic from '@anthropic-ai/sdk';
import { storeAudio } from './indexedDBService';

// API Keys
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const ANTHROPIC_API_KEY = process.env.REACT_APP_ANTHROPIC_API_KEY;

if (!OPENAI_API_KEY || !ANTHROPIC_API_KEY) {
  console.error('Missing API keys. Please check your .env file.');
}

// Initialize OpenAI and Anthropic clients
const openai = new OpenAI({ apiKey: OPENAI_API_KEY, dangerouslyAllowBrowser: true });
const anthropic = new Anthropic({ apiKey: ANTHROPIC_API_KEY, dangerouslyAllowBrowser: true });

export const eventEmitter = {
  listeners: {},
  on(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  },
  emit(event, data) {
    if (this.listeners[event]) {
      this.listeners[event].forEach(callback => callback(data));
    }
  },
  removeListener(event, callback) {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
    }
  }
};

// Updated voiceMapping with default handling
const voiceMapping = {
  'Laozi': 'onyx',
  'Siddhartha Gautama': 'onyx',
  'Plato': 'onyx',
  'Arthur Schopenhauer': 'onyx',
  'Friedrich Nietzsche': 'onyx',
  'Carl Gustav Jung': 'onyx',
  'Simone de Beauvoir': 'nova',
  'Marcus Aurelius': 'onyx',
  'Rumi': 'onyx',
  'Joseph Campbell': 'onyx',
  'Dōgen Zenji': 'onyx',
  'Jesus of Nazareth': 'onyx',
  'Harriet Tubman': 'nova',
  'Mahatma Gandhi': 'onyx',
  'Nelson Mandela': 'onyx',
  'Martin Luther King Jr.': 'onyx',
  'Karl Marx': 'onyx',
  'Maya Angelou': 'nova',
  'William Shakespeare': 'onyx',
  'Johann Wolfgang von Goethe': 'onyx',
  'Jane Austen': 'nova',
  'Emily Dickinson': 'nova',
  'Virginia Woolf': 'nova',
  'Leonardo da Vinci': 'onyx',
  'Frida Kahlo': 'nova',
  'Ada Lovelace': 'nova',
  'Wolfgang Amadeus Mozart': 'onyx',
  'Albert Einstein': 'onyx',
  'Galileo Galilei': 'onyx',
  'Meister Eckhart': 'onyx'
  // Add any additional figures here
};

// Default voice if figure is not found in the mapping
const DEFAULT_VOICE = 'nova';

/**
 * Retrieves the voice associated with a given figure.
 * @param {string} figureName - The name of the historical figure.
 * @returns {string} - The voice to be used.
 */
const getFigureVoice = (figureName) => {
  return voiceMapping[figureName] || DEFAULT_VOICE;
};

/**
 * Converts plain text to HTML-formatted text.
 * @param {string} text - The plain text to convert.
 * @returns {string} - The HTML-formatted text.
 */
const generateHTMLFromText = (text) => {
  return text
    .replace(/(?:\r\n|\r|\n)/g, '<br>')
    .replace(/^\s*•/gm, '<li>')
    .replace(/^\s*\d+\./gm, '<li>')
    .replace(/\n/g, '</li>\n')
    .replace(/<\/li>\n<li>/g, '</li><li>')
    .replace(/<li>([^<]+)<li>/g, '<li>$1</li><li>')
    .replace(/<li>\s*<\/li>/g, '')
    .replace(/<br>\s*<\/li>/g, '</li>')
    .replace(/<\/li>\s*<br>/g, '</li>');
};

/**
 * Fetches instructions for a given figure from local assets.
 * @param {string} figure - The name of the historical figure.
 * @returns {Promise<string>} - The system instructions.
 */
const fetchInstructions = async (figure) => {
  console.log(`Fetching instructions for figure: ${figure}`);
  try {
    const instructions = await import(`../assets/instructions/${figure}.json`);
    console.log(`Received instructions for ${figure}:`, instructions.system);
    return instructions.system;
  } catch (error) {
    console.error(`Error fetching instructions for ${figure}:`, error);
    throw new Error(`Failed to fetch instructions for ${figure}`);
  }
};

/**
 * Processes an audio blob by transcribing it and generating a chat response.
 * @param {Blob} audioBlob - The audio blob to process.
 * @returns {Promise<Object>} - The transcription, response text, and audio files.
 */
export const processAudio = async (audioBlob) => {
  try {
    const transcription = await transcribeAudio(audioBlob);
    console.log('Transcription successful:', transcription);

    eventEmitter.emit('textReady', { role: 'user', content: transcription });

    const selectedFigure = localStorage.getItem('selectedFigure') || 'Laozi';
    console.log('Selected figure:', selectedFigure);

    let conversationHistory = JSON.parse(localStorage.getItem(`history_${selectedFigure}`)) || [];
    console.log('Loaded conversation history:', conversationHistory);

    const instructions = await fetchInstructions(selectedFigure);

    conversationHistory.push({ role: 'user', content: transcription });

    console.log('Sending to Anthropic API:', {
      instructions,
      conversationHistory
    });

    const { responseText, audioFiles } = await generateChatResponseStream(instructions, conversationHistory, selectedFigure);
    console.log('Chat response generated and audio files created:', audioFiles);

    conversationHistory.push({ role: 'assistant', content: responseText });

    localStorage.setItem(`history_${selectedFigure}`, JSON.stringify(conversationHistory));
    console.log('Conversation history saved to localStorage');

    return { transcription, responseText, audioFiles };
  } catch (error) {
    console.error('Error during audio processing:', error);
    throw error;
  }
};

/**
 * Processes a text message by generating a chat response.
 * @param {string} message - The user's message.
 * @param {string} figureName - The name of the selected figure.
 * @param {boolean} isInitialMessage - Indicates if it's the initial message.
 * @returns {Promise<Object>} - The transcription, response text, and audio files.
 */
export const processTextMessage = async (message, figureName, isInitialMessage = false) => {
  try {
    const transcription = message;
    console.log('Received message:', transcription);

    eventEmitter.emit('textReady', { role: 'user', content: transcription });

    const selectedFigure = figureName;
    console.log('Selected figure:', selectedFigure);

    let conversationHistory = JSON.parse(localStorage.getItem(`history_${selectedFigure}`)) || [];
    console.log('Loaded conversation history:', conversationHistory);

    const instructions = await fetchInstructions(selectedFigure);

    if (isInitialMessage) {
      conversationHistory = [];
    }
    conversationHistory.push({ role: 'user', content: transcription });

    console.log('Sending to Anthropic API:', {
      instructions,
      conversationHistory
    });

    const { responseText, audioFiles } = await generateChatResponseStream(instructions, conversationHistory, selectedFigure);
    console.log('Chat response generated and audio files created:', audioFiles);

    conversationHistory.push({ role: 'assistant', content: responseText });

    localStorage.setItem(`history_${selectedFigure}`, JSON.stringify(conversationHistory));
    console.log('Conversation history saved to localStorage');

    return { transcription, responseText, audioFiles };
  } catch (error) {
    console.error('Error during text message processing:', error);
    throw error;
  }
};

/**
 * Transcribes an audio blob using OpenAI's Whisper API.
 * @param {Blob} audioBlob - The audio blob to transcribe.
 * @returns {Promise<string>} - The transcribed text.
 */
async function transcribeAudio(audioBlob) {
  try {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.webm');
    formData.append('model', 'whisper-1');

    const response = await openai.audio.transcriptions.create({
      file: formData.get('file'),
      model: 'whisper-1',
    });

    console.log('Received transcription:', response.text);
    return response.text;
  } catch (error) {
    console.error('Error transcribing audio:', error);
    throw error;
  }
}

/**
 * Generates a chat response stream using Anthropic's API.
 * @param {string} instructions - The system instructions.
 * @param {Array} conversationHistory - The conversation history.
 * @param {string} figureName - The name of the selected figure.
 * @returns {Promise<Object>} - The response text and audio files.
 */
async function generateChatResponseStream(instructions, conversationHistory, figureName) {
  console.log('Generating chat response with:', { instructions, conversationHistory });

  const messages = conversationHistory
    .filter(msg => msg.role === 'user' || msg.role === 'assistant')
    .map(msg => ({ role: msg.role, content: msg.content }));

  const stream = await anthropic.messages.create({
    model: "claude-3-5-sonnet-20240620",
    max_tokens: 4000,
    temperature: 1,
    system: instructions,
    messages: messages,
    stream: true,
  });

  let responseText = '';
  let sentence = '';
  let sentences = [];
  let fileIndex = 1;
  let batchSize = 1;
  const audioFiles = [];

  for await (const chunk of stream) {
    const content = chunk.delta?.text || '';
    responseText += content;
    sentence += content;

    if (sentence.endsWith('.') || sentence.endsWith('!') || sentence.endsWith('?')) {
      sentences.push(sentence);
      sentence = '';

      if (sentences.length >= batchSize) {
        const textChunk = sentences.join(' ');
        const audioFile = await convertTextToSpeech(textChunk, fileIndex, figureName);
        audioFiles.push(audioFile);

        eventEmitter.emit('audioReady', audioFile);
        eventEmitter.emit('textReady', { role: 'assistant', content: generateHTMLFromText(textChunk) });

        sentences = [];
        fileIndex += 1;
        batchSize += 1;
      }
    }
  }

  if (sentence || sentences.length > 0) {
    const remainingText = sentences.join(' ') + sentence;
    if (remainingText.trim()) {
      const audioFile = await convertTextToSpeech(remainingText, fileIndex, figureName);
      audioFiles.push(audioFile);
      eventEmitter.emit('audioReady', audioFile);
      eventEmitter.emit('textReady', { role: 'assistant', content: generateHTMLFromText(remainingText) });
    }
  }

  console.log('Generated response text:', responseText);
  console.log('Generated audio files:', audioFiles);

  return { responseText, audioFiles };
}

/**
 * Converts text to speech using OpenAI's TTS API.
 * @param {string} text - The text to convert.
 * @param {number} fileIndex - The index of the audio file.
 * @param {string} figureName - The name of the selected figure.
 * @returns {Promise<Object>} - The name and URL of the audio file.
 */
async function convertTextToSpeech(text, fileIndex, figureName) {
  const voice = getFigureVoice(figureName);
  console.log(`Converting text to speech for ${figureName} using voice: ${voice}`);

  try {
    const response = await openai.audio.speech.create({
      model: 'tts-1',
      voice: voice,
      input: text,
    });

    const audioBlob = new Blob([await response.arrayBuffer()], { type: 'audio/mpeg' });
    const fileName = `response_${figureName}_${fileIndex}.mp3`;
    await storeAudio(audioBlob, fileName);

    const audioUrl = URL.createObjectURL(audioBlob);
    return { name: fileName, url: audioUrl };
  } catch (error) {
    console.error('Error converting text to speech:', error);
    throw error;
  }
}

/**
 * Initiates a conversation by sending an initial message.
 * @param {string} language - The language to use.
 * @param {string} figureName - The name of the selected figure.
 * @returns {Promise<Object>} - The result of the conversation initiation.
 */
export const initiateConversation = async (language, figureName) => {
  try {
    const initialMessage = `Hello, who are you? Let's speak in ${language}, as I only speak ${language}`;
    await processTextMessage(initialMessage, figureName, true);

    // No need to manually emit or add messages here, as processTextMessage handles it
    return {}; // Adjust if necessary
  } catch (error) {
    console.error('Error initiating conversation:', error);
    throw error;
  }
};

// MessageInput.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Mic } from 'lucide-react';
import Spinner from './Spinn';
import './MessageInput.css';
import { processAudio } from '../services/audioService';

export default function MessageInput({ selectedFigure }) {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);
  const buttonRef = useRef(null);
  const timerRef = useRef(null);

  const startRecording = useCallback(async () => {
    if (isRecording) return;
    console.log('Recording started');
    setIsRecording(true);
    setRecordingTime(0);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = async () => {
        console.log('Recording stopped');
        stream.getTracks().forEach(track => track.stop());
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        audioChunksRef.current = [];
        await handleAudioProcessing(audioBlob);
      };
      mediaRecorderRef.current.start();
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (err) {
      console.error('Error starting recording:', err);
      setIsRecording(false);
      // Optionally, emit a system message or handle the error as needed
    }
  }, [isRecording]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(timerRef.current);
      console.log('Recording stopped');
    }
  }, []);

  const handleAudioProcessing = async (audioBlob) => {
    try {
      setIsProcessing(true);
      await processAudio(audioBlob);
      // `processAudio` will emit events to handle messages
    } catch (err) {
      console.error('Error processing audio:', err);
      // Optionally, emit a system message or handle the error as needed
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === 'Space' && !isRecording && document.activeElement.tagName !== 'INPUT') {
        e.preventDefault();
        startRecording();
      }
    };

    const handleKeyUp = (e) => {
      if (e.code === 'Space' && isRecording) {
        e.preventDefault();
        stopRecording();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isRecording, startRecording, stopRecording]);

  const handleMouseDown = startRecording;
  const handleMouseUp = stopRecording;

  return (
    <div className="message-input">
      <button
        ref={buttonRef}
        className={`record-button ${isRecording ? 'recording' : ''}`}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseUp}
        aria-label={isRecording ? "Stop recording" : "Start recording"}
        aria-pressed={isRecording}
        disabled={isProcessing}
      >
        <Mic className="microphone-icon" aria-hidden="true" />
        <span className="button-text">
          {isRecording ? `Release to Stop (${recordingTime}s)` : 'Hold to Record'}
        </span>
        <div className="waveform" aria-hidden="true">
          <div className="waveform-bar"></div>
          <div className="waveform-bar"></div>
          <div className="waveform-bar"></div>
          <div className="waveform-bar"></div>
          <div className="waveform-bar"></div>
        </div>
      </button>
      {isProcessing && <Spinner />}
    </div>
  );
}

import React from 'react';
import { ProgressBarWrapper, ProgressBarFill } from './styles';

const ProgressBar = ({ progress }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarFill style={{ width: `${progress}%` }} />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
import React, { useState, useEffect, useRef } from 'react';
import './StartConversationButton.css';

const languages = [
  'English', 'Spanish', 'Chinese', 'French', 'Arabic',
  'Portuguese', 'Russian', 'Japanese', 'Italian', 'Turkish', 'German', 'Bulgarian'
];

const StartConversationButton = ({ onLanguageSelected }) => {
  const [showLanguages, setShowLanguages] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (showLanguages) {
      positionLanguageButtons();
      animateLanguageButtons();
    }
  }, [showLanguages]);

  const handleResize = () => {
    if (containerRef.current && showLanguages) {
      positionLanguageButtons();
    }
  };

  const handleMainButtonClick = () => {
    setShowLanguages(!showLanguages);
  };

  const handleLanguageSelect = (language) => {
    onLanguageSelected(language);
    setShowLanguages(false);
  };

  const positionLanguageButtons = () => {
    const container = containerRef.current;
    const buttons = container.querySelectorAll('.language-button');
    const containerRect = container.getBoundingClientRect();
    const radius = Math.min(containerRect.width, containerRect.height) / 2.5;
    const centerX = containerRect.width / 2;
    const centerY = containerRect.height / 2;

    buttons.forEach((button, index) => {
      const angle = (index / buttons.length) * 2 * Math.PI;
      const x = centerX + radius * Math.cos(angle) - button.offsetWidth / 2;
      const y = centerY + radius * Math.sin(angle) - button.offsetHeight / 2;
      
      button.style.left = `${x}px`;
      button.style.top = `${y}px`;
    });
  };

  const animateLanguageButtons = () => {
    const buttons = containerRef.current.querySelectorAll('.language-button');
    buttons.forEach((button, index) => {
      requestAnimationFrame(() => {
        setTimeout(() => {
          button.classList.add('animate');
        }, index * 50);
      });
    });
  };

  return (
    <div className="start-conversation-wrapper">
      <div
        ref={containerRef}
        className={`start-conversation-container ${showLanguages ? 'show-languages' : ''}`}
      >
        <button
          className="main-button"
          onClick={handleMainButtonClick}
          aria-expanded={showLanguages}
          aria-label="Start Conversation"
        >
          Start Conversation
        </button>
        <div className="language-buttons">
          {languages.map((language, index) => (
            <button
              key={language}
              className="language-button"
              onClick={() => handleLanguageSelect(language)}
              aria-label={`Select ${language}`}
              tabIndex={showLanguages ? 0 : -1}
            >
              {language}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StartConversationButton;
// src/components/AboutModal.js

// src/components/AboutModal.js

import React from 'react';
import './AboutModal.css';

function AboutModal({ isOpen, onClose, aboutText, figureName }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="about-modal-overlay">
      <div className="about-modal-content">
        <button onClick={onClose} className="close-button">Close</button>
        <h2>{figureName}</h2>
        <div className="about-text">
          <p>{aboutText}</p>
          <p className="disclaimer">Note: This is a fictive AI character inspired by the historical figure.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutModal;
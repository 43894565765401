import React, { useState, useEffect, useMemo } from 'react';
import { seedsOfWisdom } from '../api/seedsdata';
import './WisdomMapModal.css';
import starryBackground from '../assets/starry-background.webp';

const categories = {
  Philosophers: ['Laozi', 'Siddhartha Gautama', 'Plato', 'Arthur Schopenhauer', 'Friedrich Nietzsche', 'Carl Gustav Jung', 'Marcus Aurelius', 'Rumi', 'Joseph Campbell', 'Dōgen Zenji'],
  Reformers: ['Jesus of Nazareth', 'Harriet Tubman', 'Mahatma Gandhi', 'Nelson Mandela', 'Simone de Beauvoir', 'Meister Eckhart', 'Martin Luther King Jr.', 'Galileo Galilei', 'Karl Marx', 'Maya Angelou'],
  Creators: ['William Shakespeare', 'Johann Wolfgang von Goethe', 'Jane Austen', 'Emily Dickinson', 'Virginia Woolf', 'Leonardo da Vinci', 'Frida Kahlo', 'Ada Lovelace', 'Wolfgang Amadeus Mozart', 'Albert Einstein'],
};

const WisdomMapModal = ({ isOpen, onClose, selectedFigure }) => {
  const [seeds, setSeeds] = useState([]);
  const [selectedSeed, setSelectedSeed] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedFigureName, setSelectedFigureName] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState({ category: false, figure: false });

  const seedPositions = useMemo(() => {
    return seeds.map(() => ({
      left: `${Math.random() * 80 + 10}%`,
      top: `${Math.random() * 80 + 10}%`
    }));
  }, [seeds]);

  useEffect(() => {
    if (selectedFigure) {
      const category = Object.keys(categories).find(cat => categories[cat].includes(selectedFigure.name));
      setSelectedCategory(category);
      setSelectedFigureName(selectedFigure.name);
      updateSeeds(selectedFigure.name);
    }
  }, [selectedFigure]);

  const updateSeeds = (figureName) => {
    const figureSeeds = seedsOfWisdom.filter(seed => seed.name.startsWith(figureName));
    setSeeds(figureSeeds);
    setSelectedSeed(null); // Reset selected seed when figure changes
  };

  if (!isOpen) return null;

  const totalSeeds = seeds.length;
  const gatheredSeeds = seeds.filter(seed => seed.gathered).length;
  const figureProgress = (gatheredSeeds / totalSeeds) * 100;

  const allSeeds = seedsOfWisdom.length;
  const allGatheredSeeds = seedsOfWisdom.filter(seed => seed.gathered).length;
  const overallProgress = (allGatheredSeeds / allSeeds) * 100;

  const handleSeedClick = (seed) => {
    setSelectedSeed(seed);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedFigureName(categories[category][0]);
    updateSeeds(categories[category][0]);
    setIsDropdownOpen({ ...isDropdownOpen, category: false });
  };

  const handleFigureChange = (figure) => {
    setSelectedFigureName(figure);
    updateSeeds(figure);
    setIsDropdownOpen({ ...isDropdownOpen, figure: false });
  };

  return (
    <div className="wisdom-map-modal">
      <div className="wisdom-map-content">
        <button className="close-button" onClick={onClose}>Close</button>
        <div className="dropdown-container">
          <div className={`custom-dropdown ${isDropdownOpen.category ? 'open' : ''}`}>
            <div className="dropdown-header" onClick={() => setIsDropdownOpen({ ...isDropdownOpen, category: !isDropdownOpen.category })}>
              {selectedCategory || 'Select Category'}
            </div>
            <div className="dropdown-list">
              {Object.keys(categories).map(category => (
                <div key={category} className="dropdown-item" onClick={() => handleCategoryChange(category)}>
                  {category}
                </div>
              ))}
            </div>
          </div>
          <div className={`custom-dropdown figure-dropdown ${isDropdownOpen.figure ? 'open' : ''}`}>
            <div className="dropdown-header" onClick={() => setIsDropdownOpen({ ...isDropdownOpen, figure: !isDropdownOpen.figure })}>
              {selectedFigureName || 'Select Figure'}
            </div>
            <div className="dropdown-list">
              {categories[selectedCategory]?.map(figure => (
                <div key={figure} className="dropdown-item" onClick={() => handleFigureChange(figure)}>
                  {figure}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="map-container" style={{backgroundImage: `url(${starryBackground})`}}>
          {seeds.map((seed, index) => (
            <div 
              key={index} 
              className={`star ${seed.gathered ? 'gathered' : ''}`}
              style={seedPositions[index]}
              onClick={() => handleSeedClick(seed)}
            >
              <div className="star-tooltip">{seed.name.split(' - ')[1]}</div>
            </div>
          ))}
        </div>
        {selectedSeed && (
          <div className="seed-details">
            <h3>{selectedSeed.name.split(' - ')[1]}</h3>
            <p>{selectedSeed.gathered ? 'Gathered' : 'Not yet discovered'}</p>
          </div>
        )}
        <div className="progress-bar">
          <div className="progress" style={{ width: `${figureProgress}%` }}>
            Progress
          </div>
        </div>
        <div className="progress-bar overall">
          <div className="progress" style={{ width: `${overallProgress}%` }}>
            Overall Progress
          </div>
        </div>
      </div>
    </div>
  );
};

export default WisdomMapModal;
// HomePage.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from '../components/TopBar';
import ChatBox from '../components/ChatBox';
import MessageInput from '../components/MessageInput';
import AboutModal from '../components/AboutModal';
import HistoryModal from '../components/HistoryModal';
import StartConversationButton from '../components/StartConversationButton';
import FigureCarousel from '../components/FigureCarousel';
import './HomePage.css';
import { historicalFigures } from '../api/figures';
import { eventEmitter, processTextMessage, initiateConversation } from '../services/audioService';
import { getAudio } from '../services/indexedDBService';

function HomePage({ onLogout }) {
  const [messages, setMessages] = useState([]);
  const [selectedFigure, setSelectedFigure] = useState(() => {
    const savedFigureName = localStorage.getItem('selectedFigure');
    return historicalFigures.find(fig => fig.name === savedFigureName) || historicalFigures[0];
  });
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [audioQueue, setAudioQueue] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [conversationStarted, setConversationStarted] = useState(false);
  const [showFigureCarousel, setShowFigureCarousel] = useState(true);
  const audioRef = useRef(null);

  const fetchHistory = useCallback((figureName) => {
    const storedHistory = localStorage.getItem(`history_${figureName}`);
    if (storedHistory) {
      const parsedHistory = JSON.parse(storedHistory);
      setMessages(parsedHistory);
      setConversationStarted(parsedHistory.length > 0);
    } else {
      setMessages([]);
      setConversationStarted(false);
    }
  }, []);

  useEffect(() => {
    fetchHistory(selectedFigure.name);
    localStorage.setItem('selectedFigure', selectedFigure.name);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [selectedFigure, fetchHistory]);

  useEffect(() => {
    const handleAudioReady = async (audioFile) => {
      setAudioQueue(prevQueue => [...prevQueue, audioFile]);
    };

    const handleTextReady = (textMessage) => {
      addMessage(textMessage);
    };

    eventEmitter.on('audioReady', handleAudioReady);
    eventEmitter.on('textReady', handleTextReady);

    return () => {
      eventEmitter.removeListener('audioReady', handleAudioReady);
      eventEmitter.removeListener('textReady', handleTextReady);
    };
  }, []);

  const addMessage = useCallback((message) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      if (lastMessage && lastMessage.role === message.role) {
        updatedMessages[updatedMessages.length - 1] = {
          ...lastMessage,
          content: lastMessage.content + message.content
        };
      } else {
        updatedMessages.push(message);
      }
      localStorage.setItem(`history_${selectedFigure.name}`, JSON.stringify(updatedMessages));
      return updatedMessages;
    });
    setConversationStarted(true);
  }, [selectedFigure.name]);

  const handleSelectFigure = useCallback((figure) => {
    setSelectedFigure(figure);
    fetchHistory(figure.name);
    setShowFigureCarousel(false);
    localStorage.setItem('selectedFigure', figure.name); // Ensure it's saved to localStorage
  }, [fetchHistory]);

  const handleOpenAboutModal = () => {
    setIsAboutModalOpen(true);
  };

  const handleCloseAboutModal = () => {
    setIsAboutModalOpen(false);
  };

  const handleOpenHistoryModal = () => {
    setIsHistoryModalOpen(true);
  };

  const handleCloseHistoryModal = () => {
    setIsHistoryModalOpen(false);
  };

  const handleSummaryGenerated = () => {
    fetchHistory(selectedFigure.name);
  };

  const handleHistoryCleared = () => {
    localStorage.removeItem(`history_${selectedFigure.name}`);
    setMessages([]);
    setConversationStarted(false);
  };

  const handleLanguageSelected = (language) => {
    sendInitialMessage(language);
  };

  const sendInitialMessage = async (language) => {
    try {
      await initiateConversation(language, selectedFigure.name);
      setConversationStarted(true);
    } catch (error) {
      console.error('Error sending initial message:', error);
      addMessage({ role: 'system', content: 'An error occurred while starting the conversation. Please try again.' });
    }
  };

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      playNextAudio();
    }
  }, [audioQueue, isPlaying]);

  const playNextAudio = async () => {
    if (audioQueue.length === 0) {
      setIsPlaying(false);
      return;
    }

    setIsPlaying(true);

    const [nextAudio, ...remainingQueue] = audioQueue;
    setAudioQueue(remainingQueue);

    console.log(`Starting to play: ${nextAudio.name}`);

    audioRef.current = new Audio(nextAudio.url);

    audioRef.current.oncanplaythrough = () => {
      audioRef.current.play().then(() => {
        audioRef.current.onended = () => {
          console.log(`Finished playing: ${nextAudio.name}`);
          URL.revokeObjectURL(nextAudio.url);
          setIsPlaying(false);
        };
      }).catch(error => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });
    };
  };

  const handleSendMessage = async (message) => {
    try {
      await processTextMessage(message, selectedFigure.name);
    } catch (error) {
      console.error('Error processing message:', error);
      addMessage({ role: 'system', content: 'An error occurred while processing your message. Please try again.' });
    }
  };

  const isMobileOrTablet = windowWidth < 1024;

  return (
    <div className={`homepage ${isMobileOrTablet ? 'mobile-tablet' : 'desktop'}`}>
      {isMobileOrTablet ? (
        <TopBar
          selectedFigure={selectedFigure}
          onSelectFigure={handleSelectFigure}
          onOpenHistoryModal={handleOpenHistoryModal}
          onLogout={onLogout}
        />
      ) : (
        <Sidebar
          selectedFigure={selectedFigure}
          onSelectFigure={handleSelectFigure}
          onOpenAboutModal={handleOpenAboutModal}
          onOpenHistoryModal={handleOpenHistoryModal}
          onLogout={onLogout}
          isMobileOrTablet={isMobileOrTablet}
        />
      )}
      <div className="main-content justify-text">
        {showFigureCarousel ? (
          <FigureCarousel
            isOpen={true}
            onClose={() => {}}
            onSelectFigure={handleSelectFigure}
          />
        ) : (
          <>
            {!conversationStarted ? (
              <StartConversationButton onLanguageSelected={handleLanguageSelected} />
            ) : (
              <>
                <ChatBox messages={messages} selectedFigureName={selectedFigure.name} />
                <MessageInput selectedFigure={selectedFigure.name} />
              </>
            )}
          </>
        )}
      </div>
      <AboutModal
        isOpen={isAboutModalOpen}
        onClose={handleCloseAboutModal}
        aboutText={selectedFigure.about}
        figureName={selectedFigure.name}
      />
      <HistoryModal
        isOpen={isHistoryModalOpen}
        onClose={handleCloseHistoryModal}
        selectedFigure={selectedFigure}
        onSummaryGenerated={handleSummaryGenerated}
        onHistoryCleared={handleHistoryCleared}
      />
    </div>
  );
}

export default HomePage;

// src/pages/LoginPage.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import './LoginPage.css';
import backgroundMusic from '../assets/music.mp3';
import backgroundImage from '../assets/login.png';
import logoImage from '../assets/logo.png';
import ParticleEffect from '../components/ParticleEffect';
import MeteorShower from '../components/MeteorShower';

// Use environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

const LandscapeWarning = () => (
  <div className="landscape-warning">
    <MeteorShower />
    <div className="warning-text">
      Screen size not supported. Please rotate your device.
    </div>
  </div>
);

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isMuted, setIsMuted] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');

  const audioRef = useRef(null);
  const portalRef = useRef(null);
  const dropdownRef = useRef(null);
  const timerRef = useRef(null);

  const revealForm = useCallback(() => {
    setIsFormVisible(true);
    if (portalRef.current) {
      portalRef.current.classList.add('portal-revealed');
      portalRef.current.style.display = 'none';
      portalRef.current.offsetHeight; // Trigger reflow
      portalRef.current.style.display = '';
    }
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    audio.volume = 0.5;
    audio.play().catch(() => {
      setShowPlayButton(true);
    });

    timerRef.current = setTimeout(revealForm, 15000);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    const handleOrientation = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleOrientation);
    handleOrientation();

    return () => {
      audio.pause();
      audio.currentTime = 0;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleOrientation);
    };
  }, [revealForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username.trim() && password.trim()) {
      try {
        const response = await fetch(`${API_URL}/api/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('token', data.token);
          onLogin(username, password);
        } else {
          const errorData = await response.json();
          showPopupMessage(errorData.error || 'Invalid credentials. Please try again.', 'error');
          setPassword(''); // Clear password field on error
        }
      } catch (error) {
        console.error('Login error:', error);
        showPopupMessage('An error occurred during login. Please try again.', 'error');
      }
    } else {
      showPopupMessage('Please enter both username and password.', 'error');
    }
  };

  const toggleMute = () => {
    const audio = audioRef.current;
    audio.muted = !audio.muted;
    setIsMuted(audio.muted);
  };

  const playBackgroundSound = () => {
    const audio = audioRef.current;
    audio.play();
    setShowPlayButton(false);
  };

  const handlePortalClick = () => {
    if (!isFormVisible) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      revealForm();
    }
  };

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCreateAccount = () => {
    showPopupMessage('SageStories is currently in beta. Account creation is limited to invited beta testers. Thank you for your interest!', 'info');
  };

  const handleLostPassword = () => {
    showPopupMessage('Password recovery is currently available only for beta testers. If you are a beta tester, please contact our support team.', 'info');
  };

  const showPopupMessage = (message, type) => {
    setPopupMessage(message);
    setPopupType(type);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  if (isLandscape && window.innerWidth <= 1023) {
    return <LandscapeWarning />;
  }

  return (
    <div className="background" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="logo-container">
        <img src={logoImage} alt="Logo" className="logo" />
      </div>
      <ParticleEffect />
      <h1 className="headline">
        <span className="headline-text">SageStories</span>
      </h1>
      <div className="portal-container">
        <div 
          className={`portal ${isFormVisible ? 'portal-revealed' : ''}`} 
          ref={portalRef} 
          onClick={handlePortalClick}
          onTouchStart={handlePortalClick}
        >
          <div className="portal-effect"></div>
          <div className="login-form">
            <form onSubmit={handleSubmit} aria-label="Login Form">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                aria-label="Username"
              />
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                aria-label="Password"
              />
              <div className="form-actions">
                <button type="submit">Enter</button>
              </div>
              <div className="dropdown" ref={dropdownRef}>
                <button type="button" onClick={handleDropdown} className="dropdown-toggle" aria-label="More options">
                  <span className="gear-icon" aria-hidden="true">⚙</span>
                </button>
                {showDropdown && (
                  <div className="dropdown-content">
                    <button onClick={handleCreateAccount}>Create Account</button>
                    <button onClick={handleLostPassword}>Lost Password</button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <audio ref={audioRef} loop>
        <source src={backgroundMusic} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {showPlayButton && (
        <button className="play-sound-button" onClick={playBackgroundSound} aria-label="Play background music">
          Play Background Sound
        </button>
      )}
      <button id="mute-button" onClick={toggleMute} aria-label={isMuted ? "Unmute" : "Mute"}>
        {isMuted ? 'Unmute' : 'Mute'}
      </button>
      {showPopup && (
        <div className="popup-overlay">
          <div className={`popup-content ${popupType}`}>
            <p>{popupMessage}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const fetchSomeData = async () => {
  try {
    const response = await fetch(`${apiUrl}/some-endpoint`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LegalPages.css';

export default function DatenschutzPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="legal-page">
      <div className="legal-content">
        <button onClick={handleGoBack} className="back-button">
          Zurück
        </button>
        <h1 className="legal-title">Datenschutzerklärung</h1>

        <section className="legal-section">
          <h2>Präambel</h2>
          <p>
            Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z. B. unserer Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").
          </p>
          <p>
            Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
          </p>
          <p>Stand: 12. September 2024</p>
          <p>
            <strong>Inhaltsübersicht</strong><br/>
            * Präambel<br/>
            * Verantwortlicher<br/>
            * Übersicht der Verarbeitungen<br/>
            * Maßgebliche Rechtsgrundlagen<br/>
            * Sicherheitsmaßnahmen<br/>
            * Internationale Datentransfers<br/>
            * Rechte der betroffenen Personen<br/>
            * Einsatz von Cookies<br/>
            * Bereitstellung des Onlineangebotes und Webhosting<br/>
            * Plugins und eingebettete Funktionen sowie Inhalte
          </p>
        </section>

        <section className="legal-section">
          <h2>Verantwortlicher</h2>
          <p>
            Michael Strasser<br/>
            Schusterstr. 50<br/>
            79098 Freiburg<br/>
            E-Mail-Adresse: chipmates@chipmates.ai
          </p>
        </section>

        <section className="legal-section">
          <h2>Übersicht der Verarbeitungen</h2>
          <p>
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen Personen.
          </p>
          <p>
            <strong>Arten der verarbeiteten Daten:</strong><br/>
            * Kontaktdaten<br/>
            * Inhaltsdaten<br/>
            * Nutzungsdaten<br/>
            * Meta-, Kommunikations- und Verfahrensdaten
          </p>
          <p>
            <strong>Kategorien betroffener Personen:</strong><br/>
            * Kommunikationspartner<br/>
            * Nutzer
          </p>
          <p>
            <strong>Zwecke der Verarbeitung:</strong><br/>
            * Kontaktanfragen und Kommunikation<br/>
            * Sicherheitsmaßnahmen<br/>
            * Verwaltung und Beantwortung von Anfragen<br/>
            * Feedback<br/>
            * Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit<br/>
            * Informationstechnische Infrastruktur
          </p>
        </section>

        <section className="legal-section">
          <h2>Maßgebliche Rechtsgrundlagen</h2>
          <p>
            <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO:</strong> Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
          </p>
          <ul>
            <li>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</li>
            <li>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</li>
          </ul>
          <p>
            <strong>Nationale Datenschutzregelungen in Deutschland:</strong> Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
          </p>
          <p>
            <strong>Hinweis auf Geltung DSGVO und Schweizer DSG:</strong> Diese Datenschutzhinweise dienen sowohl der Informationserteilung nach dem schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG verwendeten Begriffe „Bearbeitung" von „Personendaten", "überwiegendes Interesse" und "besonders schützenswerte Personendaten" werden die in der DSGVO verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes Interesse" und "besondere Kategorien von Daten" verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
          </p>
        </section>

        <section className="legal-section">
          <h2>Sicherheitsmaßnahmen</h2>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten. Diese Maßnahmen umfassen insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten.
          </p>
          <p>
            Zu den Maßnahmen gehören die Kontrolle des physischen und elektronischen Zugangs zu den Daten, die Sicherung der Verfügbarkeit und ihrer Trennung sowie die Einrichtung von Verfahren zur Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktionen auf Gefährdungen der Daten.
          </p>
          <p>
            <strong>TLS/SSL-Verschlüsselung (https):</strong> Um die Daten der Benutzer zu schützen, verwenden wir TLS/SSL-Verschlüsselung. Diese gewährleistet die sichere Übertragung von Daten zwischen unserer Website und dem Browser des Nutzers.
          </p>
        </section>

        <section className="legal-section">
          <h2>Internationale Datentransfers</h2>
          <p>
            Sofern wir Daten in einem Drittland (außerhalb der EU oder des EWR) verarbeiten, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
          </p>
          <p>
            Datentransfers in Drittländer erfolgen nur, wenn das Datenschutzniveau durch einen Angemessenheitsbeschluss anerkannt wurde, durch Standardvertragsklauseln, ausdrückliche Einwilligung oder im Rahmen gesetzlich erforderlicher Übermittlungen.
          </p>
          <p>
            <strong>EU-US Trans-Atlantic Data Privacy Framework:</strong> Bestimmte Unternehmen in den USA bieten durch das Data Privacy Framework (DPF) ein anerkanntes Datenschutzniveau, das im Rahmen eines Angemessenheitsbeschlusses als sicher anerkannt wurde.
          </p>
        </section>

        <section className="legal-section">
          <h2>Rechte der betroffenen Personen</h2>
          <p>
            Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, darunter:
          </p>
          <ul>
            <li>Widerspruchsrecht</li>
            <li>Widerrufsrecht bei Einwilligungen</li>
            <li>Auskunftsrecht</li>
            <li>Recht auf Berichtigung</li>
            <li>Recht auf Löschung und Einschränkung der Verarbeitung</li>
            <li>Recht auf Datenübertragbarkeit</li>
            <li>Beschwerde bei einer Aufsichtsbehörde</li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>Einsatz von Cookies</h2>
          <p>
            Cookies sind kleine Textdateien, die Informationen auf Endgeräten speichern und Informationen aus den Endgeräten auslesen. Sie können zu verschiedenen Zwecken eingesetzt werden, etwa zur Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie zur Analyse der Besucherströme.
          </p>
          <p>
            Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Eine Einwilligung ist nur notwendig, wenn das Speichern und Auslesen der Informationen nicht unbedingt erforderlich ist.
          </p>
          <p>
            <strong>Speicherdauer:</strong><br/>
            * Temporäre Cookies: Diese werden spätestens gelöscht, nachdem der Nutzer das Online-Angebot verlassen hat.<br/>
            * Permanente Cookies: Diese bleiben auch nach dem Schließen des Endgeräts gespeichert.
          </p>
          <p>
            Nutzer können ihre Einwilligungen jederzeit widerrufen und der Verarbeitung widersprechen.
          </p>
        </section>

        <section className="legal-section">
          <h2>Bereitstellung des Onlineangebotes und Webhosting</h2>
          <p>
          Wir verarbeiten die Daten der Nutzer, um unsere Online-Dienste zur Verfügung zu stellen. Zu diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
          </p>
          <p>
            <strong>Verarbeitete Datenarten:</strong><br/>
            * Nutzungsdaten<br/>
          </p>
          <p>
            <strong>Betroffene Personen:</strong><br/>
            * Nutzer
          </p>
          <p>
            <strong>Rechtsgrundlagen:</strong><br/>
            * Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
          </p>
          <p>
            <strong>Hosting und Content-Delivery-Netzwerke (CDN):</strong> Der Serveranbieter dieser Website ist Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, Deutschland. Die Datenschutzerklärung von Hetzner finden Sie hier: <a href="https://www.hetzner.com/legal/privacy-policy">https://www.hetzner.com/legal/privacy-policy</a>.
          </p>
          <p>
            Der Provider erhebt und speichert automatisch Informationen in sogenannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Diese sind:
          </p>
          <ul>
            <li>Verwendetes Betriebssystem</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP Adresse</li>
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die Datenerhebung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Betreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.
          </p>
        </section>

        <section className="legal-section">
          <h2>Hinweis zu verwendeter Musik</h2>
          <p>
            Die auf dieser Webseite verwendeten Musikstücke wurden teils mit der KI-basierten Musikgenerationsplattform Udio (ein Produkt von Uncharted Labs, Inc.) erstellt. Die Nutzung der generierten Musikstücke erfolgt gemäß den Nutzungsbedingungen und Datenschutzrichtlinien von Udio.
          </p>
          <p>
            <a href="https://www.udio.com/terms-of-service">https://www.udio.com/terms-of-service</a><br/>
            <a href="https://www.udio.com/privacy-policy">https://www.udio.com/privacy-policy</a>
          </p>
          <p>
            Zusätzlich werden auf dieser Webseite auch Musikstücke verwendet, die nicht mit Udio generiert wurden. Diese Musikstücke sind ordnungsgemäß lizenziert und unterliegen den jeweiligen Lizenzbedingungen der Rechteinhaber. Eine vollständige Liste der verwendeten Musikstücke und der zugehörigen Lizenzbedingungen kann auf Anfrage zur Verfügung gestellt werden.
          </p>
        </section>

        <section className="legal-section">
          <h2>Streitschlichtung</h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </section>
      </div>
    </div>
  );
}

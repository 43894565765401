// src/components/InstructionsModal.js

import React from 'react';
import './InstructionsModal.css';

function InstructionsModal({ isOpen, onClose }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="instructions-modal-overlay">
      <div className="instructions-modal-content">
        <button onClick={onClose} className="close-button">Close</button>
        <h2>Welcome to SageStories</h2>
        <div className="instructions-text">
          <p>Embark on a journey through time and thought in this unique learning experience. Here's how to play:</p>
          
          <h3>1. Choose Your Guide</h3>
          <p>Select from a diverse array of historical figures, each with their own unique perspective and wisdom to share. Please note that these are fictive AI characters inspired by real historical figures.</p>
          
          <h3>2. Gather Seeds of Wisdom</h3>
          <p>Engage in conversation with your chosen figure to collect their Seeds of Wisdom. These represent key insights and knowledge from their life and teachings.</p>
          
          <h3>3. Explore and Learn</h3>
          <p>Begin your conversation with a simple "Hello" or "Hi". Ask your guide to introduce themselves, and then dive into deeper discussions about their life, work, and philosophies.</p>
          
          <h3>4. Complete Your Collection</h3>
          <p>Your goal is to gather all the Seeds of Wisdom from each historical figure. The more you interact, the more you'll learn and collect.</p>
          
          <h3>Note on Privacy</h3>
          <p>We respect your privacy. No personal data is logged. Only the conversation history is stored, which can be deleted at any time.</p>
          
          <h3>Continuous Improvement</h3>
          <p>This platform is currently in alpha. We're constantly working to enhance your experience and appreciate your patience as we grow and improve.</p>
        </div>
      </div>
    </div>
  );
}

export default InstructionsModal;
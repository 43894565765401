// Sidebar.js
import React, { useState, useCallback } from 'react';
import './Sidebar.css';
import FigureCarousel from './FigureCarousel';
import InstructionsModal from './InstructionsModal';
import WisdomMapModal from './WisdomMapModal';

function Sidebar({ selectedFigure, onSelectFigure, onOpenAboutModal, onOpenHistoryModal, onLogout, isMobileOrTablet }) {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
  const [isWisdomMapOpen, setIsWisdomMapOpen] = useState(false);

  const handleSelectFigure = useCallback((figure) => {
    onSelectFigure(figure);
    setIsCarouselOpen(false);
    try {
      localStorage.setItem('selectedFigure', figure.name); // Save to localStorage
      console.log('Saved selected figure to localStorage:', figure.name);
    } catch (error) {
      console.error('Error saving selected figure to localStorage:', error.message);
      // Optionally, implement a user-facing error notification
    }
  }, [onSelectFigure]);

  return (
    <div className={`sidebar ${isMobileOrTablet ? 'mobile-tablet' : 'desktop'}`}>
      <img src={require(`../${selectedFigure.image}`)} alt={selectedFigure.name} className="sidebar-image" />
      <h1 className="sidebar-title">{selectedFigure.name}</h1>
      <div className="sidebar-buttons">
        <button className="sidebar-button" onClick={onOpenAboutModal}>About</button>
        <button className="sidebar-button" onClick={() => setIsCarouselOpen(true)}>Choose Figure</button>
        <button className="sidebar-button" onClick={() => setIsWisdomMapOpen(true)}>Wisdom Map</button>
        <button className="sidebar-button" onClick={onOpenHistoryModal}>History</button>
        <button className="sidebar-button" onClick={() => setIsInstructionsOpen(true)}>Instructions</button>
        <button className="sidebar-button" onClick={onLogout}>Logout</button>
      </div>
      <FigureCarousel
        isOpen={isCarouselOpen}
        onClose={() => setIsCarouselOpen(false)}
        onSelectFigure={handleSelectFigure}
      />
      <InstructionsModal
        isOpen={isInstructionsOpen}
        onClose={() => setIsInstructionsOpen(false)}
      />
      <WisdomMapModal
        isOpen={isWisdomMapOpen}
        onClose={() => setIsWisdomMapOpen(false)}
        selectedFigure={selectedFigure}
      />
    </div>
  );
}

export default Sidebar;
